* {
    --af-color-red: #E30C17;
    --af-color-footer-grey: #353535;
}

@font-face {
    font-family: 'Monaco_Regular';
    src: local('Monaco_Regular'), url(./asset/resource/Monaco_Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'conthrax-lt';
    src: url('./asset/resource/font/conthrax_light_macroman/conthrax-lt-webfont.woff2') format('woff2'),
    url('./asset/resource/font/conthrax_light_macroman/conthrax-lt-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'conthrax-rg';
    src: url('./asset/resource/font/conthrax_regular_macroman/conthrax-rg-webfont.woff2') format('woff2'),
    url('./asset/resource/font/conthrax_regular_macroman/conthrax-rg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'conthrax-hv';
    src: url('./asset/resource/font/Webfonts-Conthrax/Web Fonts/conthrax_heavy_macroman/conthrax-hv-webfont.woff2') format('woff2'),
    url('./asset/resource/font/Webfonts-Conthrax/Web Fonts/conthrax_heavy_macroman/conthrax-hv-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'conthrax-sb';
    src: url('./asset/resource/font/Webfonts-Conthrax/Web Fonts/conthrax_semibold_macroman/conthrax-sb-webfont.woff2') format('woff2'),
    url('./asset/resource/font/Webfonts-Conthrax/Web Fonts/conthrax_semibold_macroman/conthrax-sb-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'conthrax-bold';
    src: url('./asset/resource/font/Webfonts-Conthrax/Web Fonts/conthrax_bold_macroman/conthrax-bd-webfont.woff2') format('woff2'),
    url('./asset/resource/font/Webfonts-Conthrax/Web Fonts/conthrax_bold_macroman/conthrax-bd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    color: #ffffff;
    background: #000000;
    font-size: 1.0rem;
    font-family: conthrax-rg !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.text_arrowhacks {
    font-size: 0.9rem;
    font-family: conthrax-bold !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: bold;
}

.main-wrapper {
    margin: 20px auto;
    width: 80vw;
    padding-top: 140px;
    padding-bottom: 50px;
}

.background-grey {
    background-color: #4f4f4f;
}

.sticky-bottom {
    /*display: flex;*/
    /*position: fixed;*/
    /*width: 100%;*/
    /*left: 0px;*/
    /*bottom: 0px;*/
}

/*.app {*/
/*    text-align: center;*/
/*}*/
.slogan-logo {
    /*margin: -150px 0 -150px 0;*/
    width: 550px;
}


.app-logo {
    height: 20px;
    margin: 10px 10px;
    cursor: pointer;
    /*padding-left: 20px;*/
}

.suspense_logo {
    display: flex;
    justify-content: center;
    padding-top: 20vh;
}

.app-bar-top {
    z-index: 1000 !important;
    position: fixed !important;
    width: 100vw;
    /*height: 130px;*/
    background-color: black !important;
}

.switcher {
    font-family: conthrax-rg;
    color: white;
    background-color: black;
    border: unset;
    /*font-weight: bolder;*/
    font-size: 1.0rem;
    padding-right: 10px;
}

.phone-call {
    width: 20px;
    height: 20px;
}

.text-size-l {
    font-size: 1.0rem;
    margin: 0;
}

.text-size-xl {
    font-size: 1.2rem;
    margin: 0;
}

.text-size-xxl {
    font-size: 1.4rem;
    margin: 0;
}

.text-size-xxxl {
    font-size: 1.6rem;
    margin: 0;
}

.text-color-white {
    color: white !important;
}

a:link,
a:visited,
a:hover,
a:active {
    cursor: pointer;
    color: var(--af-color-red);
}

h1 {
    font-size: 1.8rem;
    margin-top: 0;
}

h2 {
    font-size: 1.6rem;
    margin-top: 0;
}

h3 {
    font-size: 1.4rem;
    margin-top: 0;
}

.navigation-header {
    margin: -50px 0 0 0;
    padding-bottom: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

.rounded-corner-top-xxxxl {
    border-radius: 50px 50px 0 0;
}

.rounded-corner-bottom-xxxxl {
    border-radius: 0 0 50px 50px;
}

.text-size-m {
    font-size: 1.4rem !important;
}

.fullCenter {
    display: flex;
    justify-content: center;
    width: 100% !important;
}

.k-button {
    padding: 0 10px !important;
}

.k-badge-container {
    margin-right: 8px;
}

.k-card {
    color: #ffffff !important;
    background-color: #000000 !important;
}

.k-card-header {
    color: #ffffff !important;
    background-color: #000000 !important;
}

/*.profile-logo {*/
/*    !*width: 200px;*!*/
/*    !*object-fit: none !important;*!*/
/*}*/

.row {
    display: flex;
    flex-wrap: wrap;
    /*max-height: 300px;*/
    /*height: 250px;*/
}

.col {
    flex: 0 0 50%;
    margin-left: 0;
    max-width: 45%;
    /*padding-left: 30px;*/
    border-radius: 30px;
    /*margin-bottom: 40px;*/
    border-left-color: var(--af-color-red) !important;
}
.col-text {
    flex: 0 0 50%;
    /*margin-left: 0;*/
    /*max-width: 45%;*/
    /*padding-left: 30px;*/
    /*background-color: #4f4f4f;*/
    border-radius: 20px 20px;
    /*margin-bottom: 40px;*/
    padding: 0 10px;
    border-style: solid !important;
    border-left-color: var(--af-color-red) !important;
    border-left-width: 2px !important;
    border-right-width: 0px !important;
    border-top-width: 0px !important;
    border-bottom-width: 0px !important;
}

.col-full {
    flex: 0 0 100%;
    margin-left: 0;
    max-width: 100vw;
    font-size: 1.2em;
    padding-left: 30px;
}

.image-height-xl {
    height: 150px;
}

.image-height-xxl {
    height: 300px;
}

.text-align-center {
    text-align: center;
}

.align-image {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.row-direction {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.column-direction {
    display: flex;
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.justify-left {
    justify-content: left;
}

.action-buttons {
    align-items: flex-end !important;
    margin-top: 20px !important;
}

.card-box-default {
    width: 250px;
}

.k-button-text {
    color: white;
    font-size: 1.0rem;
    font-weight: normal;
}

.k-card-body, .k-card-header {
    padding: 0 !important;
}

.k-card, .k-card-body, .k-appbar-section, .k-appbar-top, .k-button-text {
    font-family: conthrax-rg !important;
}

.k-card-title {
    font-weight: 800 !important;
    font-size: 1.0rem !important;
}

.k-appbar-section {
    color: white !important;
}

.k-appbar-bottom {
    height: 150px;
    margin-top: 50px !important;
}

.color-white {
    color: white !important;
}

div.k-appbar-section:nth-child(3) > button:nth-child(1) > span:nth-child(1),
div.k-appbar-section:nth-child(3) > button:nth-child(3) > span:nth-child(1) {
    color: white;
    font-weight: 400;
}

.k-card-title, .k-card-subtitle {
    font-family: conthrax-rg !important;
    border-style: solid !important;
    border-left-color: var(--af-color-red) !important;
    border-left-width: 2px !important;
    border-right-width: 0px !important;
    border-top-width: 0px !important;
    border-bottom-width: 0px !important;
    padding-left: 10px;
}

.k-card-list {
    flex-flow: wrap !important;
}

.k-card-deck {
    flex-flow: wrap !important;
}

.k-card-actions {
    background-color: white !important;
    justify-content: space-evenly !important;
}

.padding-left-right-l {
    padding: 0 5px !important;
}

.padding-left-s {
    padding-left: 5px;
}

.padding-left-l {
    padding-left: 40px;
}

.padding-bottom-s {
    padding-bottom: 5px;
}

.padding-bottom-m {
    padding-bottom: 10px !important;
}

.padding-bottom-l {
    padding-bottom: 15px;
}

.padding-bottom-xl {
    padding-bottom: 20px;
}

.padding-bottom-xxl {
    padding-bottom: 25px;
}

.padding-bottom-xxxl {
    padding-bottom: 30px;
}

.padding-bottom-xxxxl {
    padding-bottom: 50px;
}

.padding-bottom-5xl {
    padding-bottom: 70px;
}

.padding-top-xl {
    padding-top: 20px;
}

.padding-top-xxl {
    padding-top: 25px;
}

.padding-top-xxxl {
    padding-top: 30px;
}

.padding-top-xxxxl {
    padding-top: 50px;
}

.padding-top-5xl {
    padding-top: 100px;
}

/*.margin-left-right-l {*/
/*    margin: 5px;*/
/*}*/

.dev-icon {
    height: 50px;
    width: 50px;
}

/*.redhat-logo {*/
/*    width: 150px;*/
/*}*/

/*.laptop-logo {*/
/*    width: 500px;*/
/*}*/

/*Smartphones*/
/*#######################################*/
/*#######################################*/
/*#######################################*/
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 667px) {

    body {
        font-size: 0.8rem;
    }

    .main-wrapper {
        margin: 10px auto;
        width: 97vw;
        padding-top: 30px;
        padding-bottom: 50px;
    }

    .text_arrowhacks {
        font-size: 0.8rem;
    }

    .slogan-logo {
        /*height: 100px;*/
    }

    .slogan-logo {
        /*margin: -100px 0 -100px 0;*/
        width: 320px;
    }

    .app-logo {
        height: 15px;
        /*margin: 10px 5px;*/

        /*height: 120px;*/
        /*margin: -25px 0px;*/
        /*padding: 0 0;*/
    }

    .navigation-header {
        margin: -25px 0 0 0;
        /*padding-bottom: 20px;*/
    }

    .row {
        height: unset;
    }

    .col {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0px;
    }
    .col-text {
        flex: 0 1 98%;
    }

    .col-full {
        padding-left: unset;
    }

    .image-height-xl {
        height: 120px;
    }

    .image-height-xxl {
        height: 180px;
    }

    h1 {
        font-size: 1.4rem;
    }

    h2 {
        font-size: 1.2rem;
        margin-top: 0;
    }

    h3 {
        font-size: 1.1rem;
        margin-top: 0;
    }

    .text-size-l {
        font-size: 0.8rem;
        margin: 0;
    }

    .text-size-xl {
        font-size: 1.0rem;
        margin: 0;
    }

    .text-size-xxl {
        font-size: 1.2rem;
        margin: 0;
    }

    .text-size-xxxl {
        font-size: 1.4rem;
        margin: 0;
    }

    .margin-top-l {
        margin-top: 10px;
    }

    .padding-bottom-s {
        padding-bottom: 5px;
    }

    .padding-bottom-m {
        padding-bottom: 5px !important;
    }

    .padding-bottom-xl {
        padding-bottom: 15px;
    }

    .padding-bottom-xxl {
        padding-bottom: 20px;
    }

    .padding-bottom-xxxl {
        padding-bottom: 25px;
    }

    .padding-bottom-xxxxl {
        padding-bottom: 30px;
    }

    .padding-bottom-5xl {
        padding-bottom: 50px;
    }

    .padding-top-xl {
        padding-top: 20px;
    }

    .padding-top-xxl {
        padding-top: 25px;
    }

    .padding-top-xxxl {
        padding-top: 30px;
    }

    .padding-top-xxxxl {
        padding-top: 40px;
    }

    .padding-top-5xl {
        padding-top: 60px;
    }

    .k-button-text {
        font-size: 0.8rem;
    }

    .switcher {
        font-size: 0.8rem;
        padding-right: 0px;
    }

    .k-button {
        padding: 0 5px 0 0 !important;
    }

    .k-appbar-top {
        padding: 10px 0 0 0 !important;
    }

    .k-appbar-bottom {
        /*height: 0px;*/
        height: 250px;
        margin-top: unset !important;
        padding: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        align-content: center !important;
    }

    .k-appbar .k-appbar-section {
        align-self: baseline !important;
    }

    .card-box-default {
        width: 170px;
    }

    .k-card-image {
        align-self: center !important;
        padding-bottom: 10px !important;
    }

    /*.k-card card-box-default k-card-vertical {*/
    .k-card-vertical {
        width: 90% !important;
    }

}
